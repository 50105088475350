<template>
    <div class="flex column center" style="margin-bottom:4rem;">
        <div class="new_box">
            <div class="titlee">省创新研究院中科育成中心领导莅临历思调研</div>
            <p class="time">2021-11-29</p>
            <div class="col8" style="margin:2rem auto">
                <img class="col8" v-lazy="require('../../assets/news/ls/3-1.png')">
            </div>
            <p class="space_content">11月22日，福建省创新研究院中科育成中心主任冯先伟一行莅临厦门历思科技服务有限公司（以下简称历思）开展工作调研，了解历思发展规划和技术需求。

</p>
<p class="space_content">
历思领导向冯主任介绍了公司的基本情况。历思是一家拥有多专业物证优势技术的国家级高新技术企业，目前历思旗下有3家司法鉴定所、1家检测集团公司、1家生物科技公司、1家价值评估公司。历思自主研发的多类产品已申报并获批多项国内、国际发明专利（主要包含美国、日本、欧盟）与实用新型专利。下一阶段，历思将在毒品新型检测平台等核心产品研发上持续加大投入，力求做出完全具有自主知识产权的新产品，取得行业领先的原创成果。
</p>
<p class="space_content">
冯主任对历思取得的成绩表示祝贺，并介绍了福建省创新研究院中科育成中心的战略定位、业务发展方向等。他表示，希望以本次工作调研为契机，探索双方的合作研发方式，为历思匹配中科院研究所的相关技术资源，进一步提升企业产品的创新能力，从而拓宽发展思路。
</p>
<p class="space_content">
调研期间，双方就新一代涉毒物质检验设备的关键技术攻关进行了深入探讨，希望双方发挥各自优势，推动企业的技术创新和产业发展。
</p>



        </div>
        <div class="flex wrap .col442" style="width:100%;text-align:left;margin-top:1rem">
            <div style="margin-bottom:1rem" class="col4 flex  start1">
                <div class="flex" style="margin-right:10%">
                    <p style="white-space:nowrap">上一篇：</p>
                    <router-link class="a" :to="'/news/lsnews/2'"><p class="ellipsis1">庆贺！我所曾建晟主任当选厦门市司法鉴定协会监事长</p></router-link>


                </div>
            </div>
            <div style="margin-bottom:1rem" class="col4 flex  start1">
                <div class="flex">
                    <p style="white-space:nowrap">下一篇：</p>
                  <router-link class="a" :to="'/news/lsnews/4'"><p class="ellipsis1">历思承办案件入选福建省高级人民法院2015年十大典型案件</p></router-link>
                </div>
            </div>
            <div class="col2 flex  end1 center">
                <router-link class="a" style="color:#fff" :to="'/news/lsnews'"><el-button type="primary">返回列表</el-button></router-link >
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            data:{

            }
        }
    },
}
</script>
